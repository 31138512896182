import AxiosService from '@/services/AxiosService'
class LandingService {
  getTopCounties() {
    return AxiosService.get('/dashboard/top?limit=10')
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}
export default new LandingService()
