<template>
  <div class="page-home">
    <div class="wrapper">
      <header :class="{ active: openedSidebar }" class="header">
        <div class="content content_in">
          <div class="header__menu" @click="openedSidebar = !openedSidebar">
            <div :class="{ active: openedSidebar }" class="icon-menu">
              <div class="sw-topper"></div>
              <div class="sw-bottom"></div>
              <div class="sw-footer"></div>
            </div>
          </div>
          <a class="header__logo" href="/">
            <img alt="" src="../assets/images/home/logo-white.svg" />
          </a>
          <div class="header__manager">
            <div class="manager">
              <div class="manager__item">
                <a class="manager__link" href="https://t.me/Robert_ph" target="_blank">@Robert_ph</a>
              </div>
              <div class="manager__item">
                <a class="manager__link" href="https://t.me/Leo31PH" target="_blank">@Leo31PH</a>
              </div>
              <div class="manager__chat">
                <div class="manager__chat-label">chat</div>
                <a class="manager__link" href="https://t.me/publisher_push_house" target="_blank">@publisher_push_house</a>
              </div>
            </div>
          </div>
          <nav class="header__entry entry-menu">
            <ul>
              <li>
                <router-link class="btn _entry" to="/login">{{ $t("landing.login") }}</router-link>
              </li>
              <li>
                <router-link class="btn _registration" to="/registration">{{ $t("landing.register") }}</router-link>
              </li>
            </ul>
          </nav>
          <div class="header__lang language">
            <select-language simple />
          </div>
          <router-link class="header__login" to="/login">
            <svg fill="none" viewBox="0 0 29 29" xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                d="M7.25 20.2552H21.75C25.7541 20.2552 29 23.494 29 27.4892C29 28.2882 28.3508 28.936 27.55 28.936C26.8064 28.936 26.1935 28.3775 26.1098 27.6579L26.0926 27.2342C25.9652 25.0401 24.2045 23.2833 22.0056 23.1562L21.75 23.1488H7.25C4.84756 23.1488 2.9 25.0921 2.9 27.4892C2.9 28.2882 2.25081 28.936 1.45 28.936C0.649187 28.936 0 28.2882 0 27.4892C0 23.5991 3.07734 20.4262 6.93551 20.2619L7.25 20.2552H21.75H7.25ZM14.5 0C19.3049 0 23.2 3.88653 23.2 8.68081C23.2 13.4751 19.3049 17.3616 14.5 17.3616C9.69512 17.3616 5.8 13.4751 5.8 8.68081C5.8 3.88653 9.69512 0 14.5 0ZM14.5 2.89359C11.2967 2.89359 8.69995 5.48461 8.69995 8.6808C8.69995 11.877 11.2967 14.468 14.5 14.468C17.7032 14.468 20.3 11.877 20.3 8.6808C20.3 5.48461 17.7032 2.89359 14.5 2.89359Z"
                fill="white"
                fill-rule="evenodd"
              />
            </svg>
          </router-link>
        </div>
      </header>
      <section class="offer">
        <div class="content">
          <div class="row">
            <div class="col-12 col-md-8 align-self-center">
              <div class="offer__group">
                <div class="offer__text">
                  <div class="offer__slogan">
                    {{ $t("landing.help_making_cash") }}
                  </div>
                  <h1>
                    {{ $t("landing.income") }}<br />&nbsp;Push
                    {{ $t("landing.subscriptions") }}
                  </h1>
                  <p>{{ $t("landing.platform") }}<br />{{ $t("landing.push_subs") }}</p>
                </div>
                <router-link class="btn _offer" to="/login">{{ $t("landing.start") }}</router-link>
                <div class="arrow-scroll-mobile">
                  <svg fill="none" viewBox="0 0 30 17" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.5858 16.4142C14.3668 17.1953 15.6332 17.1953 16.4142 16.4142L29.1421 3.68629C29.9232 2.90524 29.9232 1.63891 29.1421 0.857864C28.3611 0.076815 27.0948 0.0768151 26.3137 0.857864L15 12.1716L3.68629 0.857865C2.90524 0.0768161 1.63891 0.0768162 0.857864 0.857865C0.0768151 1.63891 0.0768152 2.90524 0.857864 3.68629L13.5858 16.4142ZM13 13L13 15L17 15L17 13L13 13Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
              <div class="offer-direction">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <h2>{{ $t("landing.webmaster") }}</h2>
                    <div class="offer-direction__text _webmaster">
                      {{ $t("landing.create_platform") }} <br />
                      {{ $t("landing.set_code_upload_files") }} <br />
                      {{ $t("landing.get_80_percent") }}
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <h2>{{ $t("landing.arbitrage") }}</h2>
                    <div class="offer-direction__text _arbitrator">
                      {{ $t("landing.create_platform") }} <br />
                      {{ $t("landing.select_lending") }} <br />
                      {{ $t("landing.launch_traffic") }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="mouse-animation"></div>
            </div>
            <div class="col-12 col-md-4">
              <div class="offer-slider">
                <div class="offer-slider__item">
                  <img alt="" src="../assets/images/home/phone-ru-1.webp" />
                </div>
              </div>
              <!-- Тут слайдер из скриншотов phone-ru-1.webp, phone-ru-2.webp, phone-ru-3.webp -->
            </div>
          </div>
        </div>
      </section>
      <section class="benefits">
        <div class="content">
          <h2 class="title">{{ $t("landing.why_we") }}</h2>
          <div class="row">
            <div class="col-6 col-lg-3">
              <div class="benefit">
                <div class="benefit__value">700М+</div>
                <div class="benefit__desc">{{ $t("landing.daily_see") }}</div>
              </div>
            </div>
            <div class="col-6 col-lg-3">
              <div class="benefit">
                <div class="benefit__value">$0.001</div>
                <div class="benefit__desc">
                  {{ $t("landing.min_click_price") }}
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-3">
              <div class="benefit">
                <div class="benefit__value">100%</div>
                <div class="benefit__desc">{{ $t("landing.ads_view") }}</div>
              </div>
            </div>
            <div class="col-6 col-lg-3">
              <div class="benefit">
                <div class="benefit__value">24/7</div>
                <div class="benefit__desc">{{ $t("landing.instant_pay") }}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="formats">
        <div class="content">
          <h2 class="title">{{ $t("landing.ad_formats") }}</h2>
          <div class="formats__item">
            <div class="row flex-row-reverse">
              <div class="col-12 col-lg-7">
                <div class="formats-preview">
                  <div class="formats-preview__pc">
                    <div class="preview-push-pc">
                      <img alt="" src="../assets/images/home/push-preview-pc-ru.svg" />
                    </div>
                  </div>
                  <div class="formats-preview__mobile">
                    <div class="formats-preview__time">12:53</div>
                    <div class="formats-preview__date">
                      {{ $t("landing.time") }}
                    </div>
                    <div class="preview-push-mobile">
                      <img alt="" src="../assets/images/home/push-preview-mobile-ru.svg" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-5 align-self-center">
                <div class="formats__text">
                  <h3>Push ADS</h3>
                  <p>{{ $t("landing.ui_format") }}</p>
                  <ul>
                    <li>{{ $t("landing.support") }}</li>
                    <li>{{ $t("landing.google_coresponds") }}</li>
                    <li>{{ $t("landing.mislead_prevent") }}</li>
                    <li>{{ $t("landing.fast_setup") }}</li>
                  </ul>
                </div>
                <div class="btn-center-mob">
                  <router-link class="btn" to="/login">{{ $t("landing.try") }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="price">
        <div class="content">
          <h2 class="title">{{ $t("landing.top_countries") }}</h2>
          <div class="row">
            <div class="col-12 col-md-7">
              <div class="price__slider">
                <div class="price__slider-item">
                  <div class="c-table">
                    <div class="inner">
                      <table class="table _no-wrap" role="grid">
                        <thead>
                          <tr>
                            <th>{{ $t("dashboard.table.country") }}</th>
                            <th>
                              {{ $t("dashboard.table.average_price_mob") }}
                            </th>
                            <th>
                              {{ $t("dashboard.table.average_income_mob") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(country, index) in countries" :key="index">
                            <td>
                              <div class="country font-weight-bold">
                                <gb-flag :code="country.iso" height="22px" width="32px" />

                                {{ country.iso }}
                              </div>
                            </td>
                            <td>
                              <span class="text-center d-block font-weight-bold">$ {{ country.avg_price_mob }}</span>
                            </td>

                            <td>
                              <span class="text-center d-block font-weight-bold">$ {{ country.avg_profit_per_thousand_mob }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5 d-flex">
              <div class="price__text">
                <p>
                  <strong>{{ $t("landing.ui_format") }}</strong>
                </p>
                <p class="sep _h10"></p>
                <p>{{ $t("landing.partner_info") }}</p>

                <p class="sep _h10"></p>
                <p class="btn-center-mob">
                  <router-link class="btn" to="/login">{{ $t("landing.join") }}</router-link>
                </p>
                <p>
                  <small>* {{ $t("landing.price_details") }}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="payments">
        <div class="content">
          <div class="banner-push">
            <div class="row align-items-center justify-content-between">
              <div class="col-12 col-md-6">
                <h2 class="mb-3">{{ $t("landing.need_push") }}</h2>
                <p class="mb-0">{{ $t("landing.push_info") }}</p>
              </div>
              <div class="col-12 col-md-auto pt-md-2 pt-4">
                <a class="btn _push" href="https://push.house/" target="_blank">{{ $t("landing.buy_traffic") }}</a>
              </div>
            </div>
          </div>
          <div class="payments__list">
            <div class="row align-items-center justify-content-center justify-content-lg-between">
              <div class="col-6 col-md-4 col-lg-auto d-flex justify-content-center">
                <img alt="" src="../assets/images/home/payment/webmoney.svg" />
              </div>
              <div class="col-6 col-md-4 col-lg-auto d-flex justify-content-center">
                <img alt="" src="../assets/images/home/payment/capitalist.svg" />
              </div>
              <div class="col-6 col-md-4 col-lg-auto d-flex justify-content-center">
                <img alt="" src="../assets/images/home/payment/visa.svg" />
              </div>
              <div class="col-6 col-md-4 col-lg-auto d-flex justify-content-center">
                <img alt="" src="../assets/images/home/payment/mastercard.svg" />
              </div>
              <div class="col-6 col-md-4 col-lg-auto d-flex justify-content-center">
                <img alt="" src="../assets/images/home/payment/bitcoin.svg" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="footer">
        <div class="content">
          <div class="row align-items-center justify-content-lg-between flex-lg-nowrap">
            <div class="col-12 col-lg-auto order-lg-0">
              <div class="footer__logo">
                <img alt="" src="../assets/images/home/logo-white.svg" width="126" />
              </div>
            </div>
            <div class="col-12 col-md-4 col-lg-auto order-lg-3 ml-lg-auto">
              <a class="footer__email" href="mailto:push.house.partner@gmail.com">
                <svg fill="none" height="13" viewBox="0 0 17 13" width="17" xmlns="http://www.w3.org/2000/svg">
                  <path
                    clip-rule="evenodd"
                    d="M17 10.0455V2.95455C17 1.32364 15.64 0 13.9643 0H3.03571C1.36 0 0 1.32364 0 2.95455V10.0455C0 11.6764 1.36 13 3.03571 13H13.9643C15.64 13 17 11.6764 17 10.0455ZM15.7857 3.57503V10.0455C15.7857 11.0264 14.9721 11.8182 13.9643 11.8182H3.03571C2.02786 11.8182 1.21429 11.0264 1.21429 10.0455V3.57503L6.79389 7.26821C7.82604 7.94776 9.17396 7.94776 10.2061 7.26821L15.7857 3.57503ZM15.6339 2.24548C15.3546 1.61912 14.7111 1.18182 13.9643 1.18182H3.03571C2.28893 1.18182 1.64536 1.61912 1.36607 2.24548L7.47396 6.28728C8.09325 6.70092 8.90675 6.70092 9.52604 6.28728L15.6339 2.24548Z"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </svg>
                push.house.partner@gmail.com
              </a>
            </div>
            <div class="col-12 col-md-8 col-lg-auto order-lg-2">
              <ul class="footer__menu">
                <li>
                  <a href="#" target="_blank">{{ $t("landing.terms_of_use") }}</a>
                </li>
                <li>
                  <a href="#" target="_blank">{{ $t("landing.privacy_policy") }}</a>
                </li>
              </ul>
            </div>
            <div class="col-12 col-lg-auto order-lg-1 mr-lg-auto">
              <div class="footer__copyright">
                © {{ new Date().getFullYear() }} {{ $t("landing.all_rights") }} <br />Partners.House by
                <a href="https://push.house/" target="_blank">Push.House</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <aside :class="{ active: openedSidebar }" class="sidebar">
        <div class="sidebar__content">
          <select-language leftMenu />

          <nav class="sidebar__entry entry-menu">
            <ul>
              <li>
                <router-link class="btn _entry" to="/login">{{ $t("landing.login") }}</router-link>
              </li>
              <li>
                <router-link class="btn _registration" to="/registration">{{ $t("landing.register") }}</router-link>
              </li>
            </ul>
          </nav>
          <div class="sidebar__manager">
            <div class="manager">
              <div class="manager__item">
                <div class="manager__icon align-center">
                  <svg v-svg role="presentation" size="0 0 20 18" symbol="telegram" />
                </div>
                <div class="manager__label">{{ $t("landing.manager") }}</div>
                <a class="manager__link" href="https://t.me/Leo31PH" target="_blank">@Leo31PH</a>
              </div>
              <div class="manager__item">
                <div class="manager__icon align-center">
                  <svg v-svg role="presentation" size="0 0 20 18" symbol="telegram" />
                </div>
                <div class="manager__label">{{ $t("landing.manager") }}</div>
                <a class="manager__link" href="https://t.me/Robert_ph" target="_blank">@Robert_ph</a>
              </div>
              <div class="manager__chat">
                <div class="manager__chat-label">chat</div>
                <a class="manager__link" href="https://t.me/publisher_push_house" target="_blank">@publisher_push_house</a>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <div :class="{ active: openedSidebar }" class="menu-bg" @click="close"></div>
    </div>
  </div>
</template>

<script>
import serviceLanding from "@/api/serviceLanding";
import SelectLanguage from "@/components/SelectLanguage.vue";
import "@/assets/css/landing.scss";

export default {
  name: "Landing",
  data() {
    return {
      openedSidebar: false,
      countries: [],
    };
  },
  components: {
    SelectLanguage,
  },
  mounted() {
    serviceLanding.getTopCounties().then((res) => {
      if (res.status === 200) {
        this.countries = res.data;
      }
    });
  },
  methods: {
    close() {
      if (!this.openedSidebar) {
        return;
      }
      this.openedSidebar = false;
    },
  },
};
</script>